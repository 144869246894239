import React, { FunctionComponent } from 'react';
import { StyledLink } from '../link/styled-link.component';

export type RecaptchaProps = {
	elementID?: string;
	verifyCallbackName?: string;
	verifyCallback?: () => void;
};

/**
 * Renders a container for Google's reCAPTCHA component.
 * We also render a disclaimer text vs. relying on Google's badge
 *
 * @see https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
 */
export const Recaptcha: FunctionComponent<RecaptchaProps> = ({ elementID = 'g-recaptcha', verifyCallbackName = 'verifyCallback' }) => (
	<>
		<div className="f7 theme-grey mt1">
			This site is protected by reCAPTCHA and the Google{' '}
			<StyledLink underline url="https://policies.google.com/privacy">
				Privacy Policy
			</StyledLink>{' '}
			and <StyledLink url="https://policies.google.com/terms">Terms of Service</StyledLink> apply.
		</div>
		<div data-testid={'recaptchaComponent'} id={elementID} data-verifycallbackname={verifyCallbackName}></div>
	</>
);
